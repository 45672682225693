import React, { useCallback, useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import { FC } from 'react';
import styled, { CSSProperties, ThemeProvider } from 'styled-components';
import blob from '../images/blobs/blob.png';
import x from '../images/mobile-wavy-bg.svg';
import MetaTags from '../components/MetaTags';

import { theme } from '../styles/theme';

import Background from '../components/Background';
import Header from '../components/Header2';
import LinkButton, { Button } from '../components/LinkButton';
import FooterNew from '../sections/common/FooterWithoutFormFactorHook';
import qs from 'query-string';
import * as yup from 'yup';
import OrbContainer from '../components/OrbContainer';
import useFormFactors from '../helpers/useFormFactors';

// step by step images

import { CREATE_CARD_LINK } from '../constants/links';
import { stepByStepUnordered } from '../data/stepByStepData';

const Layout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const SplitTheCostContainer = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin-top: 200px;
    margin-bottom: 200px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  max-width: 655px;
  @media ${(props) => props.theme.breakpoints.mobile} {
    max-width: 255px;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  @media ${(props) => props.theme.breakpoints.mobile} {
    row-gap: 12px;
  }
`;
const SmallTitle = styled.h1`
  text-align: center;
  color: ${(props) => props.theme.colors.dirtOrange};

  font-size: 18px;
  @media ${(props) => props.theme.breakpoints.mobile} {
    font-size: 16px;
  }
`;
const Title = styled.h2`
  font-weight: 700;
  font-size: 40px;
  font-size: 64px;
  font-size: 36px;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 32px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 65px;
  }
  line-height: 3rem;
  text-align: center;
`;
const Subtitle = styled.p`
  text-align: center;
  font-size: 14px;
  text-align: center;
  max-width: 516px;
  color: ${(props) => props.theme.colors.darkGray};
  line-height: 26px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
  }

  @media ${(props) => props.theme.breakpoints.tabletP} {
    max-width: 516px;
    line-height: 26px;
    font-size: 18px;
  }
`;

const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    max-width: 516px;
  }
  row-gap: 24px;
`;

const StyledInput = styled.input`
  padding: 12px 16px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.lightPeach};
  border: none;
  &:focus {
    border: none;
    outline: none;
  }
  &::placeholder {
    color: ${(props) => props.theme.colors.gray25};
  }
`;
const Label = styled.label`
  color: ${(props) => props.theme.colors.blackTextLight};
  font-weight: 500;
  font-size: 14px;

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 16px;
  }
`;
const Warn = styled.p`
  color: ${(props) => props.theme.colors.gray40};
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  max-width: 300px;
  margin: 0 auto;
  & > a {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }
`;
const Cta = styled(Button)`
  font-size: 14px;
  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 16px;
  }
`;

const SplitTheCostSection = () => {
  const [link, setLink] = useState('');
  const { mobile } = useFormFactors();
  return (
    <SplitTheCostContainer>
      <InnerContainer>
        <TitleContainer>
          <SmallTitle>illume group gifting</SmallTitle>
          <Title>
            Split the cost {mobile && <br />} of{' '}
            <span style={{ fontStyle: 'italic', fontWeight: 900 }}>any </span>
            gift.
          </Title>
          <Subtitle>
            We made it easy to split the cost of any gift amongst a group. No fronting costs or
            tedious Venmo requests. Just enter the URL and you’re off!
          </Subtitle>
        </TitleContainer>
        <CtaContainer>
          <form
            onSubmit={(e) => {
              // check if link is valid
              e.preventDefault();
              const baseUrl = `https://web.illumenotes.com/create/splitanygifts/confirm`;
              yup
                .string()
                .url()
                .validate(link)
                .then(() => {
                  const url = qs.stringifyUrl({ url: baseUrl, query: { url: link } });
                  window.location.href = url;
                })
                .catch(() => alert('url is invalid'));
            }}
            style={{ display: 'flex', flexDirection: 'column', rowGap: 8 }}
          >
            <Label htmlFor='url_input'>URL of Gift</Label>
            <StyledInput
              onChange={(e) => setLink(e.target.value)}
              value={link}
              placeholder='e.g. http://www.tryillume.com/marketplace/i'
              type='url'
              name='url_input'
              id='url_input'
            />

            <Cta style={{ cursor: 'pointer' }} type='submit'>
              Continue
            </Cta>
            <Warn>
              <i>
                Shipping only available in U.S. Be sure that the product your splitting ships to
                U.S.
              </i>
            </Warn>
          </form>
        </CtaContainer>
      </InnerContainer>
    </SplitTheCostContainer>
  );
};

const Blob = styled.img`
  height: 905px;
  left: -232px;
  position: absolute;
  top: -420px;
  width: 500px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    height: 745px;
    left: -28%;
    position: absolute;
    top: -5%;
    width: 763px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 800px;
    left: -80px;
    position: absolute;
    top: -360px;
    width: 800px;
  }
`;

const HowItWorksContent = styled.div`
  z-index: 1;
  margin-top: 58px;

  margin-bottom: 100px;
  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin-bottom: 100px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;
`;
const HowItWorksTitle = styled.h1`
  font-weight: 700;
  font-size: 40px;
  color: ${(props) => props.theme.colors.gray100};
  line-height: 3rem;
  text-align: center;
`;

const Steps = styled.div<{ stepAlign: React.CSSProperties['alignItems'] }>`
  column-gap: 140px;
  row-gap: 48px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: ${(props) => props.stepAlign};
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 0 1 250px;
  row-gap: 12px;
  text-align: center;
  & > .title {
    color: ${(props) => props.theme.colors.dirtOrange};
  }
  & > .step-title {
    font-weight: bold;
  }
  & > .step-subtitle {
  }
  & > .step-link {
    color: ${(props) => props.theme.colors.dirtOrange};
    text-decoration: underline;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
  }
`;

const UnorderedStep = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 250px;
  row-gap: 12px;
  align-items: center;
  @media (min-width: 768px) {
    align-items: flex-start;
  }
  & > .title {
    color: ${(props) => props.theme.colors.dirtOrange};
  }
  & > .step-title {
    font-size: 28px;
    font-weight: 420;
    line-height: 34px;
    color: ${(props) => props.theme.colors.gray100};
  }
  & > .step-subtitle {
    font-size: 18px;
    font-weight: 390;
    line-height: 26px;
    text-align: center;
    color: ${(props) => props.theme.colors.darkGray};
    @media (min-width: 768px) {
      text-align: left;
    }
  }
  & > .step-link {
    color: ${(props) => props.theme.colors.dirtOrange};
    text-decoration: underline;
    font-size: 18px;
    font-weight: 450;
    line-height: 26px;
    // make element to the bottom
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
  }
`;

const StartAGroupGiftCTAContainer = styled.div`
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 18px;
`;

const StartAGroupGiftCta = styled(LinkButton)`
  font-size: 16px;
  width: 100%;
`;

type IData = {
  img: string;
  title: string;
  subtitle: string;
  link?: {
    copy: string;
    href: string;
  };
}[];

const SmallTopTitle = styled.h2`
  color: rgba(229, 166, 25, 1);
  font-size: 18px;
  font-weight: 420;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  max-width: 300px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    max-width: 789px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    max-width: 789px;
  }
`;

const Img = styled.img`
  height: 216px;
`;

const StyledWavyBg = styled.div`
  position: absolute;
  margin-top: -48px;
  width: 738px;
  height: 100%;
  z-index: -1;
  background: ${({ theme }) => theme.colors.lightPeach4};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 768px) {
    display: none;
  }
`;

const BottomBlob = styled.img`
  height: 320px;
  bottom: 100px;
  left: -140px;
  position: absolute;
  width: auto;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    height: 560px;
    bottom: 0px;
    left: -140px;
    position: absolute;
    width: 560px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: auto;
    bottom: -640px;
    left: -400px;
    position: absolute;
    width: auto;
  }
`;

export const HowItWorksStepByStep = ({
  data = stepByStepUnordered,
  type,
  showCta = true,
  stepAlign = 'flex-start',
  title = 'How does group gifting work?',
}: {
  data: IData;
  type: 'ordered' | 'unordered';
  showCta?: boolean;
  stepAlign?: React.CSSProperties['alignItems'];
  title?: string;
}) => {
  const StepComponent = type === 'ordered' ? Step : UnorderedStep;
  return (
    <HowItWorksContent
      style={{
        position: 'relative',
      }}
    >
      <HeadingContainer>
        <HowItWorksTitle>{title}</HowItWorksTitle>
      </HeadingContainer>
      <Steps stepAlign={stepAlign}>
        {data.map((step, index) => (
          <StepComponent key={index}>
            <Img src={step.img} alt={`step ${index + 1}`} />
            {type === 'ordered' && <p className='title'>Step {index + 1}</p>}
            <p className='step-title'>{step.title}</p>
            <p className='step-subtitle'>{step.subtitle}</p>
          </StepComponent>
        ))}
      </Steps>
      {showCta && (
        <StartAGroupGiftCTAContainer>
          <StartAGroupGiftCta href={CREATE_CARD_LINK}>Start A Group Gift</StartAGroupGiftCta>
          <Warn>
            Still have questions?{' '}
            <a href='https://intercom.help/illume/en/articles/6797804-split-any-gift-faqs'>
              Learn more.
            </a>
          </Warn>
        </StartAGroupGiftCTAContainer>
      )}
    </HowItWorksContent>
  );
};

const SplitAnyGifts: FC = () => {
  const [blobElement, setBlobElement] = useState<HTMLImageElement>();
  const [bottomBlobElement, setBottomBlobElement] = useState<HTMLImageElement>();

  const formFactors = useFormFactors();

  const blobRef = useCallback((element: HTMLImageElement) => {
    setBlobElement(element);
  }, []);

  const bottomBlobRef = useCallback((element: HTMLImageElement) => {
    setBottomBlobElement(element);
  }, []);

  const orbMovementMultiplier = useMemo(
    () => (formFactors.mobile ? 10 : formFactors.tablet ? 15 : 20),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='The Celebration Platform | illume'
        description={
          'Create fun, stand-out ecards, group cards, and group gifts with illume. For birthdays, anniversaries, or any occasion. Join the new way of celebrating.'
        }
      />
      <Layout>
        <OrbContainer
          childElement={blobElement}
          multiplier={orbMovementMultiplier}
          style={{ zIndex: 1 }}
        >
          <Blob alt='' src={blob} ref={blobRef} />
        </OrbContainer>
        <Background showTopBlob={false} showBottomBlob={false} showMiddleBlob={false} />
        <Header />
        <SplitTheCostSection />
        <HowItWorksStepByStep />
        <FooterNew />
        <OrbContainer childElement={bottomBlobElement} multiplier={orbMovementMultiplier}>
          <BottomBlob alt='' src={blob} ref={bottomBlobRef} />
        </OrbContainer>
      </Layout>
    </ThemeProvider>
  );
};

export default SplitAnyGifts;
