import { getMainAppFullPath } from '../constants/getUrl';
import manWithIdeas from '../images/step-by-step/man-with-ideas.png';
import twoPeoplePointAtSomething from '../images/step-by-step/two-people-point-at-something.png';
import videoChatting from '../images/step-by-step/video-chatting.png';

export const stepByStepUnordered = [
  {
    title: 'Select a group gift',
    subtitle: 'Choose a gift from illume’s marketplace and generate a shareable link. ',
    img: twoPeoplePointAtSomething,
    link: {
      copy: 'Split the Gift',
      href: '/splitanygifts',
    },
  },
  {
    title: 'Split the cost',
    subtitle:
      'Invite others to pool funds for the gift. Users are charged when the total price is met.',
    img: manWithIdeas,
    link: {
      copy: 'Access the marketplace',
      href: getMainAppFullPath('/create/marketplace/gifts'),
    },
  },
  {
    title: 'Send to recipient',
    subtitle:
      'Send the gift via email or SMS. The recipient will enter their own address, if needed.',
    img: videoChatting,
    link: {
      copy: 'Get started today',
      href: getMainAppFullPath('/create/marketplace/gifts'),
    },
  },
];
